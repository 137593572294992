import React from 'react';
import {View, Text, Image, TouchableOpacity, StyleSheet} from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import paymentIcon from "../icons/DupeIcons-22-Payment.png";
import calendarIcon from "../icons/DupeIcons-23-Calendar.png";
import pencilIcon from "../icons/blue-pencil.png";
import trashcanIcon from "../icons/trash-can.png";
import blueLandscapeIcon from '../icons/blue-landscape-icon.png';
import checkIcon from '../icons/DupeIcons-17-Biz-Available.png'
import {getCampaignCreators, createCampaignCreator, updateCampaignOpenEnrollment} from '../services/CampaignService';
import { useEffect, useState } from 'react';
import Switch from "react-switch";


const CampaignPreview = ({ campaign, alternateBackground, campaignOpened, creatorId, openArchiveModal, archived, restore }) => {
    const navigate = useNavigate();
    const [creatorRepresented, setCreatorRepresented] = useState(false);
    const [openEnrollment, setOpenEnrollment] = useState(false);
    //only have a loading state if creatorId is passed in
    const [isLoading, setIsLoading] = useState(!!creatorId);

    const formatDate = () => {
        if (!campaign.startDate || !campaign.endDate) {
            return 'Dates'
        }
        const monthFormatter = new Intl.DateTimeFormat('default', {month: 'long', day: 'numeric'});
        const startDate = Date.parse(campaign.startDate)
        const endDate = Date.parse(campaign.endDate)
        return monthFormatter.format(startDate) + ' - ' + monthFormatter.format(endDate)
    }

    const formatCompensation = () => {
        if (campaign.id === 'template') {
            return "Compensation"
        } else if (campaign.paymentCompensation && campaign.productCompensation) {
            return "Payment and Free Product"
        } else if (!campaign.paymentCompensation && campaign.productCompensation) {
            return "$0 and Free Product"
        } else if (campaign.paymentCompensation && !campaign.productCompensation) {
            return "Payment"
        } else {
            return "$0"
        }
    }

    function addCreatorToCampaign(creatorId) {
        let req = {};
        req.creatorId = creatorId;
        req.campaignId = campaign.id;
        req.stage = 'SHORTLISTED';
        createCampaignCreator(req)
            .then(() => {
                setCreatorRepresented(true);
            })
    }

    function toggleOpenEnrollment() {
        let req = {};
        req.campaignId = campaign.id;
        req.open = !openEnrollment;
        updateCampaignOpenEnrollment(req)
            .then(resp => {
                if (resp.status === 200) {
                    setOpenEnrollment(!openEnrollment);
                }
            })
    }

    useEffect(() => {
        if (creatorId) {
            getCampaignCreators(campaign.id).then(res => {
                const allCreators = [
                    ...(res.data.active || []),
                    ...(res.data.shortlisted || []),
                    ...(res.data.applied || []),
                    ...(res.data.invited || [])
                ];
                if (allCreators.some(creator => creator.id === creatorId)) {
                    setCreatorRepresented(true);
                }
                setIsLoading(false);
            });
        }
    }, [creatorId]);

    useEffect(() => {
        setOpenEnrollment(campaign.open);
    }, [campaign.open]);

    return (
        <View style={alternateBackground ? styles.alternateCampaignPreviewContainer : styles.campaignPreviewContainer}>
            <Image 
                style={styles.campaignPreviewImage} 
                source={campaign.imageExists 
                    ? {uri: 'https://d3p3fw3rutb1if.cloudfront.net/business/photos/' + campaign.id}
                    : blueLandscapeIcon
                }
                defaultSource={blueLandscapeIcon}
                resizeMode="cover"
            />
            <View style={styles.contentContainer}>
                <View style={styles.leftColumn}>
                    <View style={styles.campaignInfoContainer}>
                        <View>
                            <Text style={styles.campaignNameText}>{campaign.name || 'Campaign Name'}</Text>
                        </View>
                        {campaignOpened && (
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <Text>Open Enrollment</Text>
                                <Switch
                                    onChange={toggleOpenEnrollment}
                                    checked={openEnrollment}
                                    height={15}
                                    width={30}
                                    handleDiameter={14}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    activeBoxShadow={'0 0 2px 3px #3bf'}
                                    onColor={"#396de0"}
                                    className="react-switch"
                                />
                            </View>
                        )}
                        <View>
                            <View style={styles.infoRow}>
                                <Image style={styles.campaignPreviewIcon} source={paymentIcon}/>
                                <Text style={styles.subtleText}>{formatCompensation()}</Text>
                            </View>
                            <View style={styles.infoRow}>
                                <Image style={styles.campaignPreviewIcon} source={calendarIcon}/>
                                <Text style={styles.subtleText}>{formatDate()}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                {campaign.id !== 'template' && <View style={styles.rightColumn}>
                    {!archived && (
                        <View style={styles.statusContainer}>
                            {!creatorId && <Image style={[styles.trashcanIcon, styles.pencilIcon]} source={trashcanIcon} onClick={() => {openArchiveModal(campaign)}}/>}
                            <Text style={styles.subtleText}>{campaign.private ? 'Private' : 'Public'}</Text>
                            {!creatorId && <Image style={[styles.campaignPreviewIcon, styles.pencilIcon]} source={pencilIcon} onClick={() => {navigate('/campaigns/' + campaign.slug + '/edit')}}/>}
                        </View>
                        )}
                    {!creatorId && !archived && <Button
                        onPress={() => navigate('/creators/search')}
                        text="Find Creators"
                        variant='tertiary'
                    />}
                    {archived && <Button
                        onPress={() => restore(campaign)}
                        text="Restore"
                        variant='tertiary'
                    />}
                    {!creatorId && !campaignOpened && (
                        <Button
                            onPress={() => navigate('/campaigns/' + (campaign.slug || ''))}
                            text="Open Campaign"
                        />
                    )}
                    {!isLoading && creatorId && !creatorRepresented && <Button
                        onPress={() => addCreatorToCampaign(creatorId)}
                        text="Add to Campaign"
                    />}
                    {!isLoading && creatorRepresented && <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Image style={styles.campaignPreviewIcon} source={checkIcon}/>
                        <Text style={[styles.subtleText, {fontSize: 16}]}>Added to Campaign</Text>
                    </View>}
                </View>}
                {campaign.id === 'template' && <View style={styles.rightColumn}>
                    <TouchableOpacity 
                        style={[styles.btn, styles.blueBtn]}
                            onPress={() => navigate('/campaigns/create')}
                        >
                            <Text style={styles.blueBtnText}>Create Campaign</Text>
                        </TouchableOpacity>
                </View>}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    contentContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
    },
    leftColumn: {
        justifyContent: "center",
    },
    campaignInfoContainer: {
        justifyContent: 'space-between',
        flex: 0.85,
    },
    rightColumn: {
        justifyContent: 'space-between',
        margin: 10,
    },
    infoRow: {
        flexDirection: 'row',
        margin: 2,
    },
    statusContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    pencilIcon: {
        marginLeft: 10,
        cursor: 'pointer',
    },
    campaignPreviewContainer: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#b8cbf5',
        marginBottom: 15,
        borderRadius: 10,
        padding: 5,
        marginLeft: '2.5%',
        marginRight: '2.5%',
        display: 'flex',
        flexDirection: 'row',
      },
    alternateCampaignPreviewContainer: {
        backgroundColor: '#f2f1f9',
        borderWidth: 1,
        borderColor: '#b8cbf5',
        marginBottom: 15,
        borderRadius: 10,
        padding: 5,
        marginLeft: '2.5%',
        marginRight: '2.5%',
        display: 'flex',
        flexDirection: 'row',
    },
    campaignPreviewImage: {
        height: 100,
        width: 100,
        borderRadius: 5,
        margin: 10,
    },
    campaignNameText: {
        color: '#737373',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 900,
        fontSize: 24,
        margin: 2
    },
    subtleText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 12
    },
    campaignPreviewIcon: {
        height: 15,
        width: 15,
        marginRight: 5,
    },
    trashcanIcon: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
    btn: {
        borderRadius: 25,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        width: 150,
    },
    lightBlueBtn: {
        backgroundColor: '#e6f2ff',
    },
    blueBtn: {
        backgroundColor: '#396de0',
    },
    lightBlueBtnText: {
        color: '#396de0',
        fontFamily: "'lato', sans-serif",
        fontWeight: 'bold',
    },
    blueBtnText: {
        color: 'white',
        fontFamily: "'lato', sans-serif",
        fontWeight: 'bold',
    },
});

export default CampaignPreview;