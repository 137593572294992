import {StyleSheet, View, Image, Text, ScrollView} from "react-native-web";
import Content from "./Content";
import React, {useEffect, useRef, useState} from "react";
import {
    getAesthetics,
    getCreatorContent,
    getCreatorContentByAesthetic,
    searchCreatorContent
} from "../services/ContentService";
import AestheticBar from "./AestheticBar";
import dupeLogo from "../icons/Dupe_Logo_White.png";
import CampaignPreview from "./CampaignPreview";
import Modal from "react-modal";
import {getCampaigns} from "../services/CampaignService";

const Browse = () => {
    const [images, setImages] = useState([]);
    const [additionalImages, setAdditionalImages] = useState([]);
    const [aesthetics, setAesthetics] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const searchRef = useRef();
    const aestheticRef = useRef();
    const pageRef = useRef();
    const contentRef = useRef();
    const countryRef = useRef();
    const [addToCampaignModal, setAddToCampaignModal] = useState(false);
    const campaignAddRef = useRef(null);
    const [addingCreator, setAddingCreator] = useState({})
    const [campaigns, setCampaigns] = useState([]);

    function closeModal() {
        setAddToCampaignModal(false);
        setAddingCreator({});
    }

    function openAddToCampaignModal(creator) {
        console.log(creator);
        setAddingCreator(creator);
        setAddToCampaignModal(true);
    }

    function retrieveNextContent() {
        if (searchRef.current != null && searchRef.current !== "") {
            let req = {};
            req.label = searchRef.current;
            req.page = pageRef.current;
            req.content_type = contentRef.current;
            req.country = countryRef.current;
            searchCreatorContent(req)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setAdditionalImages(resp.data);
                        //setPage(page + 1);
                        pageRef.current = pageRef.current + 1;
                    }
                })
        } else if (aestheticRef.current) {
            getCreatorContentByAesthetic(aestheticRef.current, pageRef.current, contentRef.current, countryRef.current)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setAdditionalImages(resp.data);
                        //setPage(2);
                        pageRef.current = pageRef.current + 1;
                    }
                })
        } else {
            getCreatorContent(pageRef.current, contentRef.current, countryRef.current)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setAdditionalImages(resp.data);
                        pageRef.current = pageRef.current + 1;
                    }
                })
        }
    }

    function likeToggleFunction() {

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            let req = {};
            req.label = searchInput;
            searchRef.current = searchInput;
            req.page = 1;
            req.content_type = contentRef.current;
            req.country = countryRef.current;
            searchCreatorContent(req)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setImages(resp.data);
                        //setPage(2);
                        pageRef.current = 2;
                        aestheticRef.current = null;
                    }
                })
        }
    }

    const updateSearch = (event) => {
        setSearchInput(event.target.value)
    }

    function searchAesthetic(aestheticId) {
        getCreatorContentByAesthetic(aestheticId, 1, contentRef.current, countryRef.current)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setImages(resp.data);
                    //setPage(2);
                    pageRef.current = 2;
                    aestheticRef.current = aestheticId;
                    searchRef.current = null;
                    setSearchInput("");
                }
            })

    }

    function filterContent(contentFilter, countryFilter) {
        contentRef.current = contentFilter;
        countryRef.current = countryFilter;
        if (searchRef.current != null && searchRef.current !== "") {
            let req = {};
            req.label = searchRef.current;
            req.page = 1;
            req.content_type = contentFilter;
            req.country = countryFilter;
            searchCreatorContent(req)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setImages(resp.data);
                        //setPage(page + 1);
                        pageRef.current =  1;
                    }
                })
        } else if (aestheticRef.current) {
            getCreatorContentByAesthetic(aestheticRef.current, 1, contentFilter, countryFilter)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setImages(resp.data);
                        //setPage(2);
                        pageRef.current =  1;
                    }
                })
        } else {
            getCreatorContent(1, contentRef.current, countryRef.current)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setImages(resp.data);
                        pageRef.current =  1;
                    }
                })
        }
    }

    useEffect(() => {
        getCreatorContent(1, null, null)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setImages(resp.data);
                    pageRef.current = 2;
                }
            })
        getAesthetics()
            .then((res) => {
                setAesthetics(res.data)
            })
        getCampaigns()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCampaigns(resp.data);
                }
            })
    }, []);
    return(
        <View>
            <View style={{flexDirection: 'row', marginLeft: 10, marginTop: 10,}}>
                <View style={styles.dupeLogoView}>
                    <Image style={styles.dupeLogo} source={dupeLogo} />
                </View>
                <input value={searchInput} onChange={updateSearch} onKeyDown={handleKeyDown} style={styles.searchInput} />
            </View>
            <AestheticBar applyFilter={filterContent} searchFunc={searchAesthetic} selectedAestheticId={aestheticRef.current} aesthetics={aesthetics}/>
            <Content publicFlag={true} content={images} addCollectionEnabled={true} retrieveNextContent={retrieveNextContent}
                     additionalContent={additionalImages} likeFunc={likeToggleFunction} openAddToCampaign={openAddToCampaignModal} />
            <Modal
                isOpen={addToCampaignModal}
                onRequestClose={closeModal}
                style={styles.modal}
            >
                <View style={{backgroundColor: 'white', borderRadius: 10, padding: 20, height: '80%'}}>
                    <Text style={[styles.sectionText, {marginBottom: 10, alignSelf: 'center'}]}>Adding {addingCreator.first_name} {addingCreator.last_name} to Campaigns</Text>
                    <ScrollView showsVerticalScrollIndicator={true} ref={campaignAddRef}>
                        {campaigns && campaigns.map(campaign => (
                            <CampaignPreview key={campaign.id} alternateBackground={true} campaign={campaign} creatorId={addingCreator.user_id}/>
                        ))}
                    </ScrollView>
                </View>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    searchInput : {
        width: 500,
        border: '2px solid #396de0',
        borderRadius: 30,
        paddingLeft: 90,
    },
    dupeLogoView: {
        position: 'absolute',
        left: 0,
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: '#396de0',
        height: 30,
        borderRadius: 30,
        paddingLeft: 10,
        paddingRight: 10,
    },
    dupeLogo: {
        width: 60,
        height: 25,
    },
    sectionText: {
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 30,
        fontWeight: 'bold',
        color: '#396de0',
    },
    modal: {
        overlay: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(57, 109, 224, .5)'
        },
        content: {
            //margin: '20%',
            margin: 'auto',
            width: '50%',
            inset: 0,
            background: 'none',
            height: '100%',
            flex: 1,
            border: 'none',
            alignSelf: 'center',
            alignContent: 'center',
        }
    },
})

export default Browse