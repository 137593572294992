import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import "../stylesheets/Content.css";
import Modal from "react-modal";
import {
    getContentSummary, getDownloadDupeFile,
    getDownloadFile,
    getPublicContentSummary,
    getS3Content,
    toggleLike
} from "../services/ContentService";
import download from "../icons/DupeIcons-06-Download.png";
import pencil from "../icons/pencil.png";
import heartImg from "../icons/DupeIcons-Heart-Green.png";
import whiteHeart from "../icons/DupeIcons-08-Heart.png";
import greenHeart from "../icons/DupeIcons-08-Heart-Green.png";
import viewsImg from "../icons/DupeIcons-View.png";
import defaultProfile from "../icons/default-profile-icon.png";
import topArrowIcon from "../icons/up-arrow-icon.png";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import ReactPlayer from 'react-player/lazy'
import {ScrollView, StyleSheet, Text, View} from "react-native-web";
import CampaignPreview from "./CampaignPreview";

const Content = ({
                     content,
                     additionalContent,
                     profileFlag,
                     editFunc,
                     retrieveNextContent,
                     ownProfile,
                     portfolioFlag,
                     likeFunc,
                     publicFlag,
                     openAddToCampaign
                 }) => {

    const [images, _setImages] = useState([[], [], [], [], []]);
    const [allContent, setAllContent] = useState([]);
    const [collections, setCollections] = useState([]);
    const [allCollections, setAllCollections] = useState([]);
    const [contentToRemove, setContentToRemove] = useState({});
    const [addCollectionId, setAddCollectionId] = React.useState("");
    const [contentModal, setContentModal] = React.useState(false);
    const [createCollectionFlag, setCreateCollectionFlag] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [confirmationModal, setConfirmationModal] = React.useState(false);
    const [collectionImage, setCollectionImage] = React.useState(null);
    const [collectionName, setCollectionName] = React.useState("");
    const [collectionDescription, setCollectionDescription] = React.useState("");
    const [contentImage, setContentImage] = React.useState("");
    const [contentSummary, setContentSummary] = React.useState("");
    const [collectionEditId, setCollectionEditId] = React.useState("");
    const [collectionSearch, setCollectionSearch] = React.useState("");
    const [style, setStyle] = React.useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [url, setUrl] = React.useState(null);
    const [downloadFileName, setDownloadFileName] = React.useState(null);
    const ref = useRef(null);
    const collectionOptionRef = useRef(null);
    const collectionAddRef = useRef(null);
    const [displyTopButton, setDisplyTopButton] = React.useState(false);
    const displayTopButtonRef = useRef(false);
    const scrollRef = useRef(false);

    function closeCollectionModal() {
        setAddCollectionId("");
    }

    function closeContentModal() {
        setContentModal(false);
        searchParams.delete("content");
        setSearchParams(searchParams);
    }

    function closeConfirmationModal() {
        setContentToRemove({});
        setConfirmationModal(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }


    function openContentModal(contentId) {
        if (!searchParams.get("content") && searchParams.get("content") != contentId) {
            searchParams.delete("content");
            searchParams.append("content", contentId);
            setSearchParams(searchParams);
        }
        setStyle({
            overlay: {
                backgroundColor: 'rgba(24, 47, 96, 0.80)',
                position: 'fixed',
                zIndex: 999
            }
        })
        if (publicFlag) {
            getPublicContentSummary(contentId)
                .then(response => {
                    if (response.status === 200) {
                        setContentSummary(response.data);
                        setContentModal(true);
                    }
                })
        } else {
            getContentSummary(contentId)
                .then(response => {
                    if (response.status === 200) {
                        setContentSummary(response.data);
                        setContentModal(true);
                    }
                })
        }
    }


    function openConfirmationModal(event, image) {
        event.stopPropagation();
        setCollectionEditId(null);
        setContentToRemove(image);
        setConfirmationModal(true);
    }


    const setImages = (data) => {
        let newImages = [];
        if (windowSize.current[0] < 500) {
            newImages = [[], []];
        } else {
            newImages = [[], [], [], [], []];
        }
        if (data) {
            data.forEach((image, index) => {
                newImages[index % newImages.length].push(image)
            });
            _setImages(newImages);
            setIsLoading(false);
            setAllContent(data);
        }
    };

    const appendImages = (data) => {
        let newImages = [];
        if (windowSize.current[0] < 500) {
            newImages = [[], []];
        } else {
            newImages = [[], [], [], [], []];
        }
        if (data && data.length > 0) {
            data.forEach((image, index) => {
                newImages[index % newImages.length].push(image)
            });

            newImages.forEach((col, index) => {
                newImages[index] = images[index].concat(newImages[index]);
            });
            _setImages(newImages);
            setIsLoading(false);
            setAllContent([...allContent, additionalContent]);
        }
    };

    function likeContentPost(event, image) {
        event.stopPropagation();
        toggleLike(image.id)
            .then(resp => {
                if (resp.status == 200) {
                    const newImages = images.map(col => {
                        return col.map(img => {
                            if (img.id === image.id) {
                                return {...img, liked: !img.liked};
                            }
                            return img;
                        })
                    })
                    _setImages(newImages)
                    //likeFunc(image.id);

                }
            })
    }

    function openCampaignModal(event, creator) {
        event.stopPropagation();
        openAddToCampaign(creator);
    }

    function downloadFile(event, img) {
        event.stopPropagation();
        if (publicFlag) {
            getDownloadDupeFile(img.id)
                .then(resp => {
                    if (resp.status == 200) {
                        const url = URL.createObjectURL(new Blob([resp.data]));
                        if (img.content_type === "VIDEO") {
                            setDownloadFileName(img.user.toLowerCase().replace(" ", "-") + "-dupe.mov")
                            setUrl(url);
                        } else {
                            setDownloadFileName(img.user.toLowerCase().replace(" ", "-") + "-dupe.jpeg")
                            setUrl(url);
                        }
                        event.stopPropagation();
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            getDownloadFile(img.id)
                .then(resp => {
                    if (resp.status == 200) {
                        const url = URL.createObjectURL(new Blob([resp.data]));
                        if (img.content_type === "VIDEO") {
                            setDownloadFileName(img.user.toLowerCase().replace(" ", "-") + "-dupe.mov")
                            setUrl(url);
                        } else {
                            setDownloadFileName(img.user.toLowerCase().replace(" ", "-") + "-dupe.jpeg")
                            setUrl(url);
                        }
                        event.stopPropagation();
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    function handleScroll() {
        const contentDiv = document.getElementById('content-container-div');
        if (contentDiv.getBoundingClientRect().bottom <= (window.innerHeight + 1000) && !isLoading) {
            setIsLoading(true);
            retrieveNextContent();
        }
        if ((window.innerHeight + contentDiv.getBoundingClientRect().y < 0) && !displayTopButtonRef.current) {
            setDisplyTopButton(true);
            displayTopButtonRef.current = true;
        } else if ((window.innerHeight + contentDiv.getBoundingClientRect().y > 0) && displayTopButtonRef.current) {
            setDisplyTopButton(false);
            displayTopButtonRef.current = false;
        }
    }

    function scrollToTop() {
        scrollRef.current.scrollIntoView();
    }

    useLayoutEffect(() => {
        setImages(content);
    }, [content]);

    useLayoutEffect(() => {
        appendImages(additionalContent);
    }, [additionalContent]);

    useLayoutEffect(() => {
        if (!isLoading) {
            window.addEventListener('scroll', handleScroll, true);
            return () => window.removeEventListener('scroll', handleScroll, true);
        }
    }, [isLoading]);


    useEffect(() => {
        if (searchParams.get("content")) {
            openContentModal(searchParams.get("content"));
        } else if (contentModal) {
            closeContentModal();
        }

    }, [searchParams]);

    useEffect(() => {
        if (ref.current) {
            ref.current.click();
        }

    }, [url]);

    function handleIntersection(entries) {
        entries.forEach(entry => {
            const video = entry.target;

            if (entry.isIntersecting) {
                video.play();
            } else {
                video.pause();
            }
        });
    }


    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 1.0
        });

        const videos = document.querySelectorAll('.video');
        videos.forEach(video => {
            observer.observe(video);
        });
    }, []);

    return (
        <div className={"content-relative-div"}>
            <div className={"content-container-div"} id={"content-container-div"} ref={scrollRef}>
                {images && images.map((column, index) => (
                    <div className="image-column">
                        {column.map((image) => (
                            <div className={"content-container"}>
                                {portfolioFlag && (
                                    <img className="content-image" alt=""
                                         src={"https://d3p3fw3rutb1if.cloudfront.net/private-content/" + image.img_preview_id}
                                         onClick={() => openContentModal(image.id)}/>
                                )}
                                {!portfolioFlag && image.content_type === "VIDEO" && (
                                    // <img className="content-image" alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + image.img_preview_id} onClick={() => openContentModal(image.id)} />
                                    <ReactPlayer width={"100%"} height={"100%"} style={{borderRadius: "17.5px"}} className="content-video" url={publicFlag ? "https://d3p3fw3rutb1if.cloudfront.net/videos/" + image.img_preview_id : "https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + image.img_preview_id} muted={true} playing={true} loop={true} />
                                )}
                                {!portfolioFlag && image.content_type !== "VIDEO" && (
                                    <img className="content-image" alt="" src={publicFlag ? "https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id : "https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + image.img_preview_id} onClick={() => openContentModal(image.id)} />
                                )}
                                <div className={image.content_type === "VIDEO" ? "video-content-overlay-div" : "content-overlay-div"} onClick={() => openContentModal(image.id)}>
                                    {!publicFlag && (
                                        <img className="like-icon" alt="" onClick={(e) => likeContentPost(e, image)}
                                             src={image.liked ? greenHeart : whiteHeart}/>
                                    )}
                                    {!profileFlag && !portfolioFlag &&
                                        <div>
                                            <img className="download-icon" alt=""
                                                 onClick={(e) => downloadFile(e, image)} src={download}/>
                                        </div>
                                    }
                                    {publicFlag &&
                                        <img className="like-icon" alt="" onClick={(e) => openCampaignModal(e, image)}
                                             src="https://icon-library.com/images/white-plus-icon/white-plus-icon-3.jpg" />
                                    }
                                    <div onClick={event => event.stopPropagation()}>
                                        <a className={"user-div-preview"} target="_blank" href={'https://www.dupephotos.com/profile/' + image.username}>
                                            {image.profile_image_exists && (
                                                <img className="content-profile-image" alt=""
                                                     src={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + image.user_id}/>
                                            )}
                                            {!image.profile_image_exists && (
                                                <img className="content-profile-image" alt="" src={defaultProfile}/>
                                            )}
                                            <div>
                                                <p className={"content-profile-name font-bold"}>{image.user}</p>
                                                <p className={"content-profile-username"}>@{image.username}</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {displyTopButton && (
                <img src={topArrowIcon} className={"top-button pointer"} alt={"top-arrow-img"} onClick={scrollToTop}/>
            )}
            <Modal
                isOpen={contentModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeContentModal}
                className={"content-modal"}
                style={style}
            >
                <div className={"content-summary"}>
                    <div className={"selected-content"}>
                        {contentSummary.portfolio_flag && (
                            <img alt="not fount"
                                 src={"https://d3p3fw3rutb1if.cloudfront.net/private-content/" + contentSummary.img_preview_id}/>
                        )}
                        {!contentSummary.portfolio_flag && contentSummary.content_type !== "VIDEO" && (
                            <img alt="not fount"
                                 src={publicFlag ? "https://d3p3fw3rutb1if.cloudfront.net/photos/" + contentSummary.img_preview_id : "https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + contentSummary.img_preview_id}/>
                        )}
                        {!portfolioFlag && contentSummary.content_type === "VIDEO" && (
                            // <img className="content-image" alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id} onClick={() => openContentModal(image.id)} />
                            <video className="content-image" controls loop autoPlay>
                                <source src={publicFlag ? "https://d3p3fw3rutb1if.cloudfront.net/videos/" + contentSummary.img_preview_id : "https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + contentSummary.img_id}
                                        type={"video/mp4"}/>
                            </video>
                        )}
                    </div>
                    <div className={"content-info"}>
                        <div className={"user-div"} onClick={() => navigate('/profile/' + contentSummary.username)}>
                            {contentSummary.profile_image_exists && (
                                <img className={"summary-profile-img"}
                                     src={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + contentSummary.user_id}/>
                            )}
                            {!contentSummary.profile_image_exists && (
                                <img className={"summary-profile-img"} src={defaultProfile}/>
                            )}
                            <div className={"d-inline"}>
                                <h5>{contentSummary.user}</h5>
                            </div>
                            <div className={"d-inline"}>
                                <div className={"stats-div"}>
                                    <img className={"likes-img"} src={heartImg}/>
                                    <p className={"d-inline"}>{contentSummary.likes} &nbsp;Likes</p>
                                </div>
                                <div className={"stats-div"}>
                                    <img className={"likes-img"} src={viewsImg}/>
                                    <p className={"d-inline"}>{contentSummary.views} &nbsp;Views</p>
                                </div>
                            </div>
                        </div>
                        {!contentSummary.portfolio_flag && contentSummary.aesthetics && contentSummary.aesthetics.length > 0 && (
                            <div className={"aesthetics"}>
                                <h5 className={"font-bold"}>Aesthetics</h5>
                                <div className={"content-aesthetics-div"}>
                                    {contentSummary.aesthetics && contentSummary.aesthetics.map((aesthetic) => (
                                        <a className={"aesthetic-button"}
                                           href={'/home?aesthetic=' + aesthetic.aesthetic}>{aesthetic.aesthetic}</a>
                                    ))}
                                </div>
                            </div>
                        )}
                        {!contentSummary.portfolio_flag && contentSummary.labels &&
                            <div className={"labels"}>
                                {contentSummary.labels.map((label) => (
                                    <a className={"btn"} href={'/results?search=' + label.label}>{label.label}</a>
                                ))}
                            </div>
                        }
                        {contentSummary.portfolio_flag && (
                            <div className={"portfolio-info-div"}>
                                <h3 className={"font-bold"}>{contentSummary.title}</h3>
                                <p>{contentSummary.description}</p>
                            </div>
                        )}
                        {!contentSummary.portfolio_flag && (
                            <button className={"download-btn blue-btn"}
                                    onClick={event => downloadFile(event, contentSummary)}>Download</button>
                        )}
                    </div>
                </div>
            </Modal>
            <a href={url} download={downloadFileName} className="hidden" ref={ref}/>
        </div>
    );
}
export default Content