import React, {useEffect, useRef, useState} from 'react'
import '@sendbird/uikit-react/dist/index.css';
import {View, ScrollView, StyleSheet, Text, TouchableOpacity, Image, TextInput, Pressable} from 'react-native-web';
import SendbirdChat from "@sendbird/chat";
import {
    GroupChannelFilter, GroupChannelListOrder,
    GroupChannelModule
} from "@sendbird/chat/groupChannel";
import {
    getActiveBusinessCreatorCampaigns,
    getActiveBusinessCreators,
    getBusinessSummary
} from "../services/BusinessService";
import plusIcon from "../icons/grey-plus-btn.png";
import Modal from "react-modal";
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import Header from "./Header";
import {enableCreatorMessaging} from "../services/CreatorService";
import Select from "react-dropdown-select";
import blueLandscapeIcon from "../icons/blue-landscape-icon.png";
import {useParams, useSearchParams} from "react-router-dom";

const Message = () => {

    const [messagingConnection, setMessagingConnection] = useState({})
    const [businessSummary, setBusinessSummary] = useState({})
    const [selectedUser, setSelectedUser] = useState({})
    const [activeCreators, setActiveCreators] = useState([])
    const [activeCampaigns, setActiveCampaigns] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState({})
    const [defaultCampaign, setDefaultCampaign] = useState({})
    const [channels, setChannels] = useState([])
    const [campaignFilter, setCampaignFilter] = useState({})
    const [newMessageModal, setNewMessageModal] = useState(false)
    const [showCampaignFilter, setShowCampaignFilter] = useState(false)
    const ref = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const sb = SendbirdChat.init({
        appId: '960C8598-DEDE-43E0-89FA-45442999623C',
        modules: [
            new GroupChannelModule(),
        ],
    });

    const myColorSet = {
        '--sendbird-light-primary-500': '#396DE0',
        '--sendbird-light-primary-400': '#396DE0',
        '--sendbird-light-primary-300': '#396DE0',
        '--sendbird-light-primary-200': '#396DE0',
        '--sendbird-light-primary-100': '#396DE0',
    };

    function afterOpenModal() {

    }

    function closeModal() {
        setNewMessageModal(false);
    }

    function openChatModal() {
        if (activeCampaigns.length > 0) {
            filterActiveCreators([activeCampaigns[0]]);
            setDefaultCampaign(activeCampaigns[0]);
        }
        setNewMessageModal(true);
    }

    function filterActiveCreators(selects) {
        let campaignId = null;
        if (selects.length > 0 && selects[0] && selects[0].value) {
            campaignId = selects[0].value;
        }
        getActiveBusinessCreators(campaignId)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    if (campaignId != null) {
                        const params = {
                            customTypesFilter: [campaignId],
                            includeEmpty: true,
                        };
                        const query = sb.groupChannel.createMyGroupChannelListQuery(params);

                        if (query.hasNext) {
                            let channelMap = {};
                            let creators = {};
                            query.next()
                                .then(channels => {
                                    channels.forEach(channel => {
                                        const channelUrlParams = channel._url.split("_");
                                        channelMap[channelUrlParams[1]] = channel;
                                    })
                                    creators = resp.data.map(creator => ({
                                        ...creator,
                                        messaged: channelMap.hasOwnProperty(creator.id)
                                    }))
                                })
                                .finally(() => {
                                    setActiveCreators(creators);
                                })
                        } else {
                            setActiveCreators(resp.data);
                        }
                    }
                }
            })
    }

    function createNewMessage(user) {
        if (user.messaged) {
            loadUsers(businessSummary.businessId, defaultCampaign.value + "_" + user.id, null);
            setNewMessageModal(false);
        } else {
            enableCreatorMessaging(user.id)
                .then(resp => {
                    const params = {
                        invitedUserIds: [user.id],
                        name: user.first_name + " " + user.last_name + " and " + defaultCampaign.label,
                        operatorUserIds: [businessSummary.businessId],
                        channelUrl: defaultCampaign.value + "_" + user.id,
                        isDistinct: false,
                        customType: defaultCampaign.value,
                    };
                    sb.groupChannel.createChannel(params)
                        .then(sbResp => {
                            loadUsers(businessSummary.businessId, defaultCampaign.value + "_" + user.id, null);
                            setNewMessageModal(false);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                })
        }
    }

    function openChat(user) {
        console.log(user);
        setSelectedUser(user);
    }

    function loadUsers(businessId, channelUrl, campaignId) {
        const groupChannelFilter = new GroupChannelFilter();
        groupChannelFilter.includeEmpty = true; // Optional.
        if (campaignId != null) {
            groupChannelFilter.customTypesFilter = [campaignId];
        }
        // groupChannelFilter.publicChannelFilter  = PublicChannelFilter.PUBLIC; // Retrieve public group channels. Optional.
        // groupChannelFilter.superChannelFilter = SuperChannelFilter.SUPER; // Retrieve Supergroup channels. Optional.

        const groupChannelCollection = sb.groupChannel.createGroupChannelCollection({
            filter: groupChannelFilter,
            order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
            // ...
        });
        if (groupChannelCollection.hasMore) {
            groupChannelCollection.loadMore()
                .then(channelResp => {
                    let channelsArr = [];
                    channelResp.forEach((channel) => {
                        let newChannel = {};
                        if (channel.members && channel.members.length > 1) {
                            channel.members.forEach((member) => {
                                if (member.userId !== businessId) {
                                    newChannel.id = member.userId;
                                    newChannel.nickname = member.nickname;
                                }
                            })
                            newChannel.channel = channel;
                            if (newChannel.channel && newChannel.channel._url && newChannel.channel._url === channelUrl) {
                                openChat(newChannel);
                            }
                            channelsArr.push(newChannel);
                        }
                    });
                    setChannels(channelsArr);
                })
        }
    }

    function filterByCampaign(campaign) {
        loadUsers(businessSummary.businessId, null, campaign.value);
        setCampaignFilter(campaign);
        setShowCampaignFilter(false);
    }

    useEffect(()=>{
        getBusinessSummary()
            .then(resp => {
                if (resp.status === 200 && resp.data && resp.data.businessId) {
                    setBusinessSummary(resp.data);
                    try {
                        sb.connect(resp.data.businessId)
                            .then(connResp => {
                                setMessagingConnection(connResp);
                                loadUsers(resp.data.businessId, searchParams.get("channel"), null);
                            })
                            .catch(err => {
                                console.log(err);
                            })

                    } catch (err) {
                        // Handle error.
                    }
                }
            })
        getActiveBusinessCreatorCampaigns()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    const dropDownOptions = resp.data.map((campaign) => ({
                        label: campaign.name,
                        value: campaign.id,
                        imageExists: campaign.imageExists,
                    }));
                    setActiveCampaigns(dropDownOptions);
                }
            })
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowCampaignFilter(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return(
        <View style={styles.messageContainer}>
            <Header title="Messages" />
            <View style={styles.contentContainer}>
                <View style={styles.chatUsersContainer}>
                    <View style={styles.messagesHeader}>
                        <View>
                            <Pressable onPress={() => {setShowCampaignFilter(true)}}>
                                <Image
                                    source={blueLandscapeIcon}
                                    style={styles.addCampaignImg}
                                />
                            </Pressable>
                            {showCampaignFilter && (
                                <View style={styles.campaignFilterView} ref={ref}>
                                    <View style={styles.centeredView}>
                                        <Pressable  onPress={() => {filterByCampaign({})}}>
                                            <Text style={styles.boldBlueText}>Clear Filter</Text>
                                        </Pressable>
                                    </View>
                                    {activeCampaigns.map((campaign) => (
                                        <TouchableOpacity style={styles.dropDownCreatorView} onPress={() => {filterByCampaign(campaign)}}>
                                            <Image
                                                style={styles.campaignPreviewImage}
                                                source={campaign.imageExists
                                                    ? {uri: 'https://d3p3fw3rutb1if.cloudfront.net/business/photos/' + campaign.value}
                                                    : blueLandscapeIcon
                                                }
                                                defaultSource={blueLandscapeIcon}
                                                resizeMode="cover"
                                            />
                                            <Text style={styles.boldBlueText}>{campaign.label}</Text>
                                        </TouchableOpacity>
                                    ))}
                                </View>
                            )}
                        </View>
                        <View>
                            {campaignFilter.label && (
                                <Text style={styles.boldBlueText}>{campaignFilter.label}</Text>
                            )}
                            {!campaignFilter.label && (
                                <Text style={styles.boldBlueText}>All Messages</Text>
                            )}
                        </View>
                        <TouchableOpacity style={styles.plusIcon} onPress={openChatModal}>
                            <Image
                                source={plusIcon}
                                style={styles.addCampaignImg}
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.channelsView}>
                        {channels.map((creator, index) => (
                            <TouchableOpacity onPress={() => openChat(creator)}>
                                <View style={[index % 2 == 0 ? styles.creatorEvenRow : styles.creatorOddRow, (selectedUser.channel && selectedUser.channel.url === creator.channel.url) ? styles.selectedMessageRow : ""]}>
                                    <Image
                                        source={{uri: "https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + creator.id}}
                                        style={styles.creatorPreviewImage}
                                    />
                                    <View>
                                        <Text style={styles.boldBlueText}>{creator.nickname}</Text>
                                        {creator.channel.lastMessage && (
                                            <Text style={creator.channel.unreadMessageCount > 0 ? styles.unreadMessageText : styles.lastMessageText}>{creator.channel.lastMessage.message}</Text>
                                        )}
                                    </View>
                                    {creator.channel.unreadMessageCount > 0 && (
                                        <View style={styles.redDotView}/>
                                    )}
                                </View>
                            </TouchableOpacity>
                        ))}
                    </View>
                </View>
                <View style={styles.chatContainer}>
                    {selectedUser && selectedUser.channel && selectedUser.channel.url && (
                        <SendbirdProvider
                            appId={'960C8598-DEDE-43E0-89FA-45442999623C'}
                            userId={businessSummary.businessId}
                            colorSet={myColorSet}
                        >
                            {/* GroupChannel should be always wrapped inside SendbirdProvider */}
                            <GroupChannel channelUrl={selectedUser.channel.url} />
                        </SendbirdProvider>
                    )}
                </View>
            </View>
            <Modal
                isOpen={newMessageModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"invite-modal"}
                style={styles.modal}
            >
                <View style={styles.newMessageView}>
                    <View style={{zIndex: 1000, flexDirection: 'row', alignItems: 'center'}}>
                        <Text style={styles.boldGreyText}>New Messages</Text>
                        <View style={{flex: 1, alignItems: 'flex-end'}}>
                            <Select
                                options={activeCampaigns}
                                values={[defaultCampaign]}
                                style={{width: 200}}
                                itemRenderer={({ item, methods }) => (
                                    <TouchableOpacity style={styles.dropDownCreatorView} onPress={() => {methods.addItem(item); setDefaultCampaign(item);}}>
                                        <Image
                                            style={styles.campaignPreviewImage}
                                            source={item.imageExists
                                                ? {uri: 'https://d3p3fw3rutb1if.cloudfront.net/business/photos/' + item.value}
                                                : blueLandscapeIcon
                                            }
                                            defaultSource={blueLandscapeIcon}
                                            resizeMode="cover"
                                        />
                                        <Text style={styles.boldBlueText}>{item.label}</Text>
                                    </TouchableOpacity>
                                )}
                                onChange={(value) => filterActiveCreators(value)}
                            />
                        </View>
                    </View>
                    {activeCreators.map(creator => (
                        <TouchableOpacity onPress={() => {createNewMessage(creator)}}>
                            <View style={styles.row}>
                                <Image
                                    source={{uri: "https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + creator.id}}
                                    style={styles.creatorPreviewImage}
                                />
                                <Text style={styles.boldBlueText}>{creator.first_name} {creator.last_name}</Text>
                                <View style={styles.flexEndView}>
                                    {creator.messaged && (
                                        <Text style={styles.lightGreyText}>Continue Message</Text>
                                    )}
                                    {!creator.messaged && (
                                        <Text style={styles.lightGreyText}>Added {creator.added_date}</Text>
                                    )}
                                </View>
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        marginTop: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
    creatorOddRow: {
        backgroundColor: "#f8f8f8",
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    selectedMessageRow: {
        backgroundColor: '#dae3f8',
    },
    creatorEvenRow: {
        backgroundColor: "white",
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    messageContainer: {
        height: '100vh',
    },
    messagesHeader: {
        flexDirection: 'row',
        marginTop: 20,
        justifyContent: 'space-between',
        marginBottom: 15,
        zIndex: 10,
        paddingLeft: 15,
        paddingRight: 15,
    },
    plusIcon: {

    },
    contentContainer: {
        flexDirection: 'row',
        flex: 1,
    },
    addCampaignImg: {
        width: 20,
        height: 20,
        alignSelf: 'flex-end',
    },
    sendIcon: {
        width: 20,
        height: 20,
    },
    sendTouchable: {
        position: 'absolute',
        right: 30,
        bottom: 10,
    },
    modal: {
        overlay: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(57, 109, 224, .5)',
        },
        content: {
            margin: '27.5%',
            marginTop: '25vh',
            inset: 0,
            background: 'none',
            flex: 1,
            border: 'none',
            alignSelf: 'center',
            alignContent: 'center',
        }
    },
    newMessageView: {
        height: '60vh',
        overflow: 'scroll',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20
    },
    creatorPreviewImage: {
        width: 80,
        height: 80,
        margin: 10,
        borderRadius: 50,
    },
    messagePreviewImage: {
        width: 60,
        height: 60,
        marginLeft: 10,
        borderRadius: 50,
    },
    chatContainer: {
        width: '70%',
        flex: 1,
        backgroundColor: 'white',
    },
    chatUsersContainer: {
        width: '25%',
    },
    notesInput: {
        padding: 10,
        display: 'flex',
        color: 'grey',
        fontFamily: "'lato', sans-serif",
        borderWidth: 1,
        borderColor: '#b8cbf5',
        borderRadius: 5,
        marginRight: 20,
    },
    messageView: {
        flex: 1,
        flexDirection: 'column-reverse',
        paddingBottom: 20,
    },
    sentMessage: {
        color: 'white',
    },
    sentMessageView: {
        backgroundColor: '#396de0',
        padding: 5,
        alignSelf: 'flex-end',
        marginTop: 10,
        borderRadius: 5,
        marginRight: 15,
        maxWidth: '40%',
    },
    receivedMessage: {
        color: '#1a1a1a',
    },
    receivedMessageView: {
        backgroundColor: '#f1f1f1',
        padding: 5,
        alignSelf: 'flex-end',
        marginTop: 10,
        borderRadius: 5,
        marginLeft: 15,
        maxWidth: '40%',
        flex: 1,
    },
    receivedRow: {
        flexDirection: 'row',
        alignItems: 'end',
        flex: 1,
    },
    boldBlueText: {
        color: '#396de0',
        fontWeight: 'bold',
    },
    lastMessageText: {
        color: '#737373',
        marginTop: 5,
    },
    unreadMessageText: {
        color: '#737373',
        marginTop: 5,
        fontWeight: 'bold',
    },
    channelsView: {
        backgroundColor: '#f8f8f8',
        flex: 1,
        overflow: 'scroll',
    },
    flexEndView: {
        alignItems: 'flex-end',
        flex: 1,
    },
    lightGreyText: {
        color: '#b2b2b2',
        fontSize: 12,
    },
    boldGreyText: {
        color: '#b2b2b2',
        fontWeight: 'bold',
    },
    dropDownCreatorView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        marginTop: 5,
    },
    campaignPreviewImage: {
        height: 50,
        width: 50,
        borderRadius: 5,
        marginRight: 10,
        marginLeft: 5,
    },
    campaignFilterView: {
        position: 'absolute',
        maxHeight: 400,
        backgroundColor: 'white',
        overflow: 'scroll',
        zIndex: 20,
        width: 250,
        top: 30,
        left: 0,
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderRadius: 5,
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 15,
    },
    redDotView: {
        width: 10,
        height: 10,
        backgroundColor: 'red',
        borderRadius: 5,
        position: 'absolute',
        right: 10,
    }
});

export default Message
