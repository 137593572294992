import axios from "axios";
import dupeBusinessClient from "./DupeBusinessClient";
import dupeClient from "./DupeClient";

export async function getContent() {
    let url = "https://content-api-prod-6gxsdymdsq-ue.a.run.app/api/v1/content"
    return await axios.get(url);
}

export async function getContent2() {
    let url = "https://content-api-prod-6gxsdymdsq-ue.a.run.app/api/v1/content?page=2"
    return await axios.get(url);
}

export async function getAesthetics() {
    let url = "https://content-api-prod-6gxsdymdsq-ue.a.run.app/api/v1/aesthetics/popular"
    return await axios.get(url);
}


export async function getPublicContentSummary(contentId) {
    let url = "https://content-api-prod-6gxsdymdsq-ue.a.run.app/api/v1/content/" + contentId + "/preview"
    return await axios.get(url);
}
export async function getContentSummary(contentId) {
    return await dupeBusinessClient.get("/api/v1/businesses/content/" + contentId + "/preview").catch(error => {
        console.log(error);
    });
}

export async function toggleLike(contentId) {
    return await dupeBusinessClient.post("/api/v1/businesses/content/" + contentId + "/like", {}).catch(error => {
        console.log(error);
    });
}

export async function getDownloadFile(contentId) {
    return await dupeBusinessClient.get("/api/v1/businesses/content/" + contentId + "/download", {responseType: 'blob'}).catch(error => {
        console.log(error);
    });
}

export async function getDownloadDupeFile(contentId) {
    let url = "https://content-api-prod-6gxsdymdsq-ue.a.run.app/api/v1/content/" + contentId + "/download"
    return await axios.get(url);
}

export async function getS3Content(contentId) {
    let url = "https://d3p3fw3rutb1if.cloudfront.net/photos/"+contentId;
    return await axios.get(url, {responseType: "blob"});
}

export async function getCreatorContent(page, contentType, country) {
    let url = "/api/v1/creators/content?page=" + page;
    if (contentType != null) {
        url = url + "&content_type=" + contentType;
    }
    if (country != null) {
        url = url + "&country=" + country;
    }
    return await dupeBusinessClient.get(url).catch(error => {
        console.log(error);
    });
}

export async function searchCreatorContent(req) {
    return await dupeBusinessClient.post("/api/v1/creators/content/search", req).catch(error => {
        console.log(error);
    });
}

export async function getCreatorContentByAesthetic(aestheticId, page, contentType, country) {
    let url = "/api/v1/creators/content/aesthetics/" + aestheticId + "?page=" + page;
    if (contentType != null) {
        url = url + "&content_type=" + contentType;
    }
    if (country != null) {
        url = url + "&country=" + country;
    }
    return await dupeBusinessClient.get(url).catch(error => {
        console.log(error);
    });
}