import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {archiveCampaign, getArchivedCampaigns, getCampaigns, restoreCampaign} from "../services/CampaignService";
import plusIcon from "../icons/grey-plus-btn.png";
import {View, ScrollView, StyleSheet, Text, TouchableOpacity, Image, Pressable} from 'react-native-web';
import CampaignPreview from './CampaignPreview';
import Header from './Header';
import upArrowIcon from "../icons/up-arrow-icon.png";
import Modal from "react-modal";
import blueLandscapeIcon from "../icons/blue-landscape-icon.png";
import Button from "./Button";
const campaignTemplate = {
    id: 'template',
    name: 'Campaign Name',
}

const Outreach = () => {
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([])
    const [archivedCampaigns, setArchivedCampaigns] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState({})
    const [archiveModal, setArchiveModal] = useState(false)
    const [showArchived, setShowArchived] = useState(false)

    useEffect(()=>{
        fetchCampaigns();
        retrieveArchivedCampaigns();
    }, []);

    function fetchCampaigns() {
        getCampaigns()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCampaigns(resp.data);
                }
            })
    }

    function openArchiveModal(campaign) {
        setSelectedCampaign(campaign);
        setArchiveModal(true);
    }

    function closeModal() {
        setArchiveModal(false);
    }

    function afterOpenModal() {

    }

    function toggleShowArchived() {
        setShowArchived(!showArchived);
    }

    function retrieveArchivedCampaigns() {
        getArchivedCampaigns()
            .then(resp => {
                if (resp && resp.status === 200 && resp.data) {
                    setArchivedCampaigns(resp.data);
                }
            })
    }

    function triggerCampaignArchive() {
        archiveCampaign(selectedCampaign.id)
            .then(resp => {
                fetchCampaigns();
                retrieveArchivedCampaigns();
                setArchiveModal(false);
            })
    }

    function triggerCampaignRestore(campaign) {
        restoreCampaign(campaign.id)
            .then(resp => {
                fetchCampaigns();
                retrieveArchivedCampaigns();
                setArchiveModal(false);
            })
    }

    return(
        <View style={{height: '100vh'}}>
            <Header title="Campaigns"/>
            <View>
                <View style={styles.containerLabelDiv}>
                    <View style={styles.headerContainer}>
                        <Text style={styles.blueText}>Active Campaigns</Text>
                        <TouchableOpacity onPress={() => navigate('/campaigns/create')}>
                            <Image source={plusIcon} style={styles.addCampaignImg} />
                        </TouchableOpacity>
                    </View>
                    <Text style={styles.descriptionText}>
                        Campaigns are for Influencers, people that will share your product on their social platforms to reach a wider audience and drive sales through content
                    </Text>
                </View>
                {!campaigns || campaigns.length < 1 ? <CampaignPreview campaign={campaignTemplate} /> :
                <ScrollView>
                    <View>
                        {campaigns.map(campaign => (
                            <CampaignPreview key={campaign.id} campaign={campaign} openArchiveModal={openArchiveModal} />
                        ))}
                    </View>
                    <View style={styles.archivedView}>
                        <Pressable style={{flexDirection: "row", justifyContent: "space-between",}} onPress={toggleShowArchived}>
                            <Text style={styles.redText}>Archived Campaigns ({archivedCampaigns.length})</Text>
                            <Image source={upArrowIcon} style={styles.arrowIcon} />
                        </Pressable>
                        {showArchived && archivedCampaigns && archivedCampaigns.length > 0 && (
                            <View style={{paddingTop: 15,}}>
                                {archivedCampaigns.map(campaign => (
                                    <CampaignPreview alternateBackground={true} archived={true} key={campaign.id} campaign={campaign} openArchiveModal={openArchiveModal} restore={triggerCampaignRestore} />
                                ))}
                            </View>
                            )}
                    </View>
                </ScrollView>
                }
            </View>
            <Modal
                isOpen={archiveModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"invite-modal"}
                style={styles.modal}
            >
                <View style={{alignItems: 'center', backgroundColor: 'white', borderRadius: 10, padding: 20}}>
                    <Text>Are you sure you want to archive {selectedCampaign.name}?</Text>
                    <Image
                        style={styles.campaignPreviewImage}
                        source={selectedCampaign.imageExists
                            ? {uri: 'https://d3p3fw3rutb1if.cloudfront.net/business/photos/' + selectedCampaign.id}
                            : blueLandscapeIcon
                        }
                        defaultSource={blueLandscapeIcon}
                        resizeMode="cover"
                    />
                    <View style={{flexDirection: "row"}}>
                        <Button style={{marginRight: 5}} text={"Cancel"} variant={"secondary"} onPress={() => setArchiveModal(false)} />
                        <Button style={{marginLeft: 5}} text={"Archive"} variant={"warning"} onPress={triggerCampaignArchive} />
                    </View>
                </View>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    containerLabelDiv: {
        marginLeft: '2.5%',
        marginRight: '2.5%',
        marginBottom: 20,
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    blueText: {
        color: '#396de0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        paddingTop: 10,
    },
    descriptionText: {
        fontSize: 14,
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        marginBottom: 0,
    },
    addCampaignImg: {
        width: 20,
        height: 20,
        alignSelf: 'flex-start',
    },
    modal: {
        overlay: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(57, 109, 224, .5)'
        },
        content: {
            margin: '30%',
            inset: 0,
            background: 'none',
            flex: 1,
            border: 'none',
            alignSelf: 'center',
            alignContent: 'center',
            outline: 'none',
        }
    },
    campaignPreviewImage: {
        height: 100,
        width: 100,
        borderRadius: 5,
        margin: 10,
    },
    archivedView: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#b8cbf5',
        marginBottom: 15,
        borderRadius: 10,
        padding: 15,
        marginLeft: '2.5%',
        marginRight: '2.5%',
    },
    arrowIcon: {
        height: 20,
        width: 20,
    },
    redText: {
        color: '#e03939',
        fontFamily: "'lato', sans-serif",
        fontWeight: 'bold',
    },
});

export default Outreach
